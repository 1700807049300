import { CSS_TRANSITION_END_EVENT } from './utils/css-properties';

export default class Page {
	constructor(name) {
		this.name = name;
		this.animations = [];
	}

	setup(rootNode, pageConfig) {
		return new Promise((resolve, reject) => {
			this.rootNode = rootNode;
			this.config = pageConfig;
			this._setup(pageConfig, resolve);
		});
	}

	_setup(pageConfig, resolve) {
		resolve(this);
	}

	enter() {
		return new Promise((resolve, reject) => {
			const handler = e => {
				elm.removeEventListener(CSS_TRANSITION_END_EVENT, handler);
				this.rootNode.classList.add('started');
				this._enter(resolve);
			};

			const elm = this.getEnterElement();
			elm.addEventListener(CSS_TRANSITION_END_EVENT, handler);
			this.rootNode.classList.add('enter');
		});
	}

	_enter(resolve) {
		resolve(this);
	}

	init() {}

	exit() {
		this.clean();

		return new Promise((resolve, reject) => {
			this._exit(resolve);
		});
	}

	_exit(resolve) {
		setTimeout(() => {
			resolve(this);
		}, 50);
	}

	clean() {
		this.rootNode.innerHTML = '';

		[...document.querySelector('head').querySelectorAll('link, script, style')]
			.concat([...document.querySelector('body').querySelectorAll('link, script, style')])
			.forEach(function(node) {
				if (!node.hasAttribute('data-is-common')) {
					node.parentNode.removeChild(node);
				}
			});

		this.rootNode.classList.remove('enter');
		this.rootNode.classList.remove('started');
	}

	handleResize({ currentSize, windowWidth, windowHeight, scrollTop, cookiesWarningHeight }) {
		this.animations.forEach(animation => {
			animation.updateCoords({
				currentSize,
				windowWidth,
				windowHeight,
				scrollTop,
				cookiesWarningHeight,
			});
		});
	}

	updateAnimation(time, scrollTop) {
		this.animations.forEach(animation => {
			animation.update(time, scrollTop);
		});
	}

	getEnterElement() {
		return this.rootNode;
	}

	getExitElement() {
		return this.rootNode;
	}
}
