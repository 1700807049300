export default class Canvas {
	constructor(spritesRootPath) {
		this.sprites = {};
		this.spritesUrl = `${spritesRootPath}/Sprites/`;
		this.loadedLeaf = false;

		this.fps = 30;
		this.interval = 1000 / this.fps;
	}

	loadLeaf(callback) {
		this.leafCtx = this.setupCanvas($('.js-canvas').get(0));
		if (this.leafCtx) {
			this.loadSprite('leaf', 114, function() {
				if (callback) {
					callback();
				}
			});
		}
	}

	loadClock(callback) {
		this.clockCtx = this.setupCanvas($('.js-watch-canvas').get(0));
		if (this.clockCtx) {
			this.loadSprite('reloj', 120, function() {
				if (callback) {
					callback();
				}
			});
		}
	}

	pad(num) {
		var str = '' + num;
		var pad = '00000';
		return pad.substring(0, pad.length - str.length) + str;
	}

	setupCanvas(canvas) {
		if (!canvas) {
			return;
		}
		var rect = canvas.getBoundingClientRect();

		canvas.width = rect.width;
		canvas.height = rect.height;

		var ctx = canvas.getContext('2d');
		return ctx;
	}

	loadSprite(name, length, callback) {
		var counter = 0;
		this.sprites[name] = [];

		var imageLoaded = function() {
			counter++;
			if (counter === length && callback) {
				callback();
			}
		};
		for (var i = 1; i <= length; i++) {
			var img = new Image();
			var url = this.spritesUrl + name + '/';
			img.src = url + name + '-' + this.pad(i) + '.png';
			this.sprites[name].push(img);
			img.onload = function() {
				imageLoaded();
			};
		}
	}

	animateClock() {
		var that = this;
		var ctx = this.clockCtx;
		var i = 1;
		var then = Date.now();
		var now;
		var delta;
		var animation;

		var animate = function() {
			animation = requestAnimationFrame(animate);
			now = Date.now();
			delta = now - then;

			if (delta > that.interval) {
				then = now - (delta % that.interval);
				ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
				ctx.drawImage(that.sprites.reloj[i], 0, 0, ctx.canvas.width, ctx.canvas.height);
				i++;
				if (i === 120) {
					i = 0;
				}
			}
		};
		animate();
	}

	animateLeaf() {
		var that = this;
		var ctx = this.leafCtx;
		var i = 1;
		var then = Date.now();
		var now;
		var delta;
		var animation;

		var animate = function() {
			animation = requestAnimationFrame(animate);
			now = Date.now();
			delta = now - then;

			if (delta > that.interval) {
				then = now - (delta % that.interval);
				ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
				ctx.drawImage(that.sprites.leaf[i], 0, 0, ctx.canvas.width, ctx.canvas.height);

				i++;
				if (i === 114) {
					that.loadedLeaf = true;
					return cancelAnimationFrame(animation);
				}
			}
		};
		animate();
	}
}
