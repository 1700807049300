import Swiper from 'swiper';
import { Howl, Howler } from 'howler';
import debounce from '../utils/debounce';
import Canvas from './canvas';
import { mediaQuery } from './responsive';
import { TimelineMax } from 'gsap';
import getBodySize from '../utils/get-body-size';

class PreloadImage {
	constructor(node) {
		this.node = node;
		this.type = this.node.tagName.toLowerCase();
		this.src =
			this.type === 'img'
				? this.node.getAttribute('data-src')
				: this.node.getAttribute('data-poster');
		this.img = new Image();
	}

	preload(callback) {
		return new Promise((resolve, reject) => {
			const handler = () => {
				this.img.removeEventListener('load', handler);
				const attr = this.type === 'img' ? 'src' : 'poster';
				this.node.setAttribute(attr, this.src);

				if (callback) {
					callback();
				}
				resolve();
			};

			this.img.addEventListener('load', handler);
			this.img.src = this.src;
		});
	}
}

class Scene {
	constructor(element, mode, initY, endY) {
		this.element = element;
		this.initY = initY;
		this.endY = endY;
		this.height = 0;
		this.mode = mode;
		this.videos = [];
		this.audios = [];
		this.isLoaded = false;
		this.images = [];
		this.loadedVideos = 0;
	}

	setPreloadImages(config, canvas) {
		if (config && config.items) {
			this.images = config.items.map(function(node) {
				return new PreloadImage(node);
			});
		}

		if (config && config.sprite) {
			this.sprite = config.sprite;
			this.canvas = canvas;
		}
	}

	loadImages(doneCallback, updateCallback) {
		if (this.images.length === 0) {
			this.isLoaded = true;
			doneCallback();
		}

		const promises = this.images.map(function(img) {
			return img.preload(updateCallback);
		});

		if (this.sprite) {
			const spritePromise = new Promise((resolve, reject) => {
				if (this.sprite == 'leaf') {
					this.canvas.loadLeaf(function() {
						resolve();
					});
				}
				if (this.sprite == 'clock') {
					this.canvas.loadClock(function() {
						resolve();
					});
				}
			});

			promises.push(spritePromise);
		}

		Promise.all(promises).then(() => {
			this.isLoaded = true;
			doneCallback();
		});
	}
}

export default class Experience {
	constructor(config) {
		this.config = config;
		var that = this;
		this.position = 0;
		this.index = 0;
		this.dotOrder = [0, 1, 2, 6, 5, 4, 3];
		this.scenes = [];
		this.videos = [];
		this.audios = [];
		this.direction = 'down';
		this.soundEnabled = false;
		this.moving = false;
		this.canvas_isSeted = false;

		this.sceneLoader;
		this.loadedClock;
		this.totalHeightDescent;
		this.totalHeightAscent;
		this.isChrome = false;
		this.isSafari =
			/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

		this.fullScreenMode =
			document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
		this.allowResize = true;

		this.timesAnimations = {
			duration: [5000, 9000, 3000, 3000, 6000, 3000],
			enterDelay: [2000, 5300, 4300, 3300, 3300, 4300],
			exitDelay: [0, 0, 0, 0, 0, 800],
		};

		this.canvas = new Canvas(this.config.assetsRootPath);
		this.sceneAudios = this.configureAudios(this.config.assetsRootPath);
		this.events = this.configureEvents(this);

		this.timeouts = [];
		this.intervals = [];
	}

	configureAudios(rootPath) {
		return [
			{
				up: {
					fadeIn: 1500,
					fadeOut: 2200,
				},
				down: {
					fadeIn: 1000,
					fadeOut: 2500,
				},
				enter: [
					{
						src: `${rootPath}/audios/01_viento.ogg`,
						aac: `${rootPath}/audios/01_viento.aac`,
						loop: true,
					},
				],
				leave: [],
			},
			{
				up: {
					fadeIn: 2000,
					fadeOut: 1500,
				},
				down: {
					fadeIn: 2500,
					fadeOut: 4800,
				},
				enter: [
					{
						src: `${rootPath}/audios/02_naturaleza.ogg`,
						aac: `${rootPath}/audios/02_naturaleza.aac`,
						loop: true,
					},
				],
				leave: [],
			},
			{
				up: {
					fadeIn: 1000,
					fadeOut: 2000,
				},
				down: {
					fadeIn: 4000,
					fadeOut: 1000,
				},
				enter: [
					{
						src: `${rootPath}/audios/03_submerge.ogg`,
						aac: `${rootPath}/audios/03_submerge.aac`,
						loop: false,
					},
					{
						src: `${rootPath}/audios/04_deep_sea.ogg`,
						aac: `${rootPath}/audios/04_deep_sea.aac`,
						loop: true,
					},
					{
						src: `${rootPath}/audios/05_burbujas.ogg`,
						aac: `${rootPath}/audios/05_burbujas.aac`,
						loop: true,
					},
				],
				leave: [],
			},
			{
				up: {
					fadeIn: 1000,
					fadeOut: 1000,
				},
				down: {
					fadeIn: 1000,
					fadeOut: 2500,
				},
				enter: [
					{
						src: `${rootPath}/audios/08_viento_cima.ogg`,
						aac: `${rootPath}/audios/08_viento_cima.aac`,
						loop: true,
					},
				],
				leave: [],
			},
			{
				up: {
					fadeIn: 1000,
					fadeOut: 1000,
				},
				down: {
					fadeIn: 1000,
					fadeOut: 1000,
				},
				enter: [
					{
						src: `${rootPath}/audios/06_cascada.ogg`,
						aac: `${rootPath}/audios/06_cascada.aac`,
						loop: true,
					},
					{
						src: `${rootPath}/audios/07_mecanismo_reloj.ogg`,
						aac: `${rootPath}/audios/07_mecanismo_reloj.aac`,
						loop: true,
					},
				],
				leave: [],
			},
			{
				up: {
					fadeIn: 1000,
					fadeOut: 1000,
				},
				down: {
					fadeIn: 1000,
					fadeOut: 1000,
				},
				enter: [
					{
						src: `${rootPath}/audios/03_submerge.ogg`,
						aac: `${rootPath}/audios/03_submerge.aac`,
						loop: false,
						avoid: true,
					},
					{
						src: `${rootPath}/audios/02_naturaleza.ogg`,
						aac: `${rootPath}/audios/02_naturaleza.aac`,
						loop: true,
					},
					{
						src: `${rootPath}/audios/07_mecanismo_reloj.ogg`,
						aac: `${rootPath}/audios/07_mecanismo_reloj.aac`,
						loop: true,
					},
				],
				leave: [],
			},
			{
				up: {
					fadeIn: 1000,
					fadeOut: 1000,
				},
				down: {
					fadeIn: 1000,
					fadeOut: 1000,
				},
				enter: [
					{
						src: `${rootPath}/audios/04_deep_sea.ogg`,
						aac: `${rootPath}/audios/04_deep_sea.aac`,
						loop: true,
					},
				],
				leave: [],
			},
		];
	}

	configureEvents(that) {
		return {
			scroll: debounce(function(event) {
				// "mousewheel" works in Chrome and IE, "DOMMouseScroll" works only in Firefox
				$('.js-transition').css('transition-delay', '0ms');
				if (event.originalEvent.wheelDelta > 0 || event.originalEvent.detail < 0) {
					return;
				} else {
					//scrollDown
					that.direction = 'down';
					if (that.scenes[that.index].mode === 'descent' && !that.moving) {
						if (that.index != 2) {
							that.downScenes();
						} else {
							that.transition('down');
							that.position = that.totalHeightAscent - $(window).height();
							that.index = 6;
							that.playVideos(that.index);
							$('.scenes-descent').fadeOut(2000);
							that.actionElements(
								that.index,
								that.index,
								that.timesAnimations.enterDelay[that.index - 1],
								that.timesAnimations.exitDelay[2]
							);
							that.stateMoving();
							that.sceneEnter(4500);
							$('.js-transition').css('transition-delay', '1000ms');
							$('.js-transition').css('transition-duration', '0ms');
							$('.js-transition').css(
								'transform',
								`translate3d(0,${-that.position}px,0)`
							);
						}
					} else {
						if (!that.moving && that.index - 1 >= 2) {
							that.upScenes_ascent();
						}
					}
				}
			}, 10),
			resize: debounce(function() {
				if (that.allowResize) {
					const tm = setTimeout(function() {
						that.preloader();
					}, 0);
					that.timeouts.push(tm);
					const tm2 = setTimeout(function() {
						window.location.reload();
					}, 200);
					that.timeouts.push(tm2);
				}
			}, 400),

			btnAudio: function() {
				that.toggleSound();
			},
			hotspot: function() {
				var target = this.dataset.for;
				var $wrapper = $('.js-content-video[data-video="' + target + '"]');
				$wrapper.addClass('is-visible');
				$wrapper
					.find('video')
					.get(0)
					.play();
			},
			video: function() {
				this.paused ? this.play() : this.pause();
			},
			detectFullscreen: function() {
				if (that.fullScreenMode) {
					const tm = setTimeout(function() {
						that.allowResize = true;
						$('.scenes').removeClass('is-safari');
					}, 1000);
					that.timeouts.push(tm);
				} else {
					that.allowResize = false;
				}
				that.fullScreenMode = !that.fullScreenMode;
				$('body').toggleClass('is-fullscreen');
				if (that.isSafari) {
					$('.js-watch-canvas').width($('.js-watch-canvas').attr('width'));
					$('.js-watch-canvas').height($('.js-watch-canvas').attr('height'));
				}
			},
			fullscreen: function() {
				that.allowResize = false;
				if (that.isSafari) {
					$('.scenes').addClass('is-safari');
				}
				var video = $(this)
					.parents('.js-content-video')
					.eq(0)
					.find('video')
					.get(0);
				if (video.requestFullScreen) {
					video.requestFullScreen();
				} else if (video.mozRequestFullScreen) {
					/* Firefox */
					video.mozRequestFullScreen();
				} else if (video.msRequestFullscreen) {
					/* IE/Edge */
					video.msRequestFullscreen();
				} else if (video.webkitRequestFullscreen) {
					if (that.scenes[that.index].mode === 'descent') {
						$('.scenes-ascent').hide();
					}
					video.webkitRequestFullscreen();
				}
			},
			closeVideo: function() {
				var $parent = $(this)
					.parents('.js-content-video')
					.eq(0);
				$parent
					.find('video')
					.get(0)
					.pause();
				$parent.removeClass('is-visible');
			},
		};
	}

	preloaderEnd() {
		$('.js-preloader').addClass('is-loaded');
		const tm = setTimeout(function() {
			$('.js-preloader').addClass('is-hidden');
		}, 1500);
		this.timeouts.push(tm);
	}

	preloader() {
		$('.js-preloader').removeClass('is-hidden');
	}

	checkChrome() {
		var isChromium = window.chrome;
		var winNav = window.navigator;
		var vendorName = winNav.vendor;
		var isOpera = typeof window.opr !== 'undefined';
		var isIEedge = winNav.userAgent.indexOf('Edge') > -1;
		var isIOSChrome = winNav.userAgent.match('CriOS');

		if (
			isChromium !== null &&
			typeof isChromium !== 'undefined' &&
			vendorName === 'Google Inc.' &&
			isOpera === false &&
			isIEedge === false
		) {
			this.isChrome = true;
			if (!this.soundEnabled) {
				$('.js-chrome-audio').addClass('is-visible');
			}
		}
	}

	loaded(nextVideo) {
		var that = this;
		this.checkChrome();
		$('.scenes').css('opacity', 1);
		this.preloaderEnd();
		that.isLoaded = true;
		that.preloadPromiseResolve();
		that.loadNextVideo(nextVideo);
	}

	preloadMobile() {
		return new Promise((resolve, reject) => {
			this.config.preloadables.forEach(function(node) {
				node.setAttribute('src', node.getAttribute('data-src'));
			});

			resolve();
		});
	}

	mobile() {
		var that = this;
		var target;
		this.preloaderEnd();

		window.APP.headerManager.canUpdateOnScroll = false;

		$(window).on('resize', () => {
			if(getBodySize() === 'large'){
				this.events.resize();
			}
		});

		$('.js-panel-mobile').on('click', function() {
			$(this)
				.closest('.panel')
				.toggleClass('is-open');
		});

		$('.js-poster-video').on('click', function() {
			target = $(this).data('mobile');
			var videoPlayer = document.getElementById(target);
			videoPlayer.addEventListener('ended', onVideoEnded, false);
			// videoPlayer.addEventListener('pause', onVideoEnded, false);
			videoPlayer.play();
			if (videoPlayer.requestFullscreen) {
				videoPlayer.requestFullscreen();
			} else if (videoPlayer.mozRequestFullScreen) {
				/* Firefox */
				videoPlayer.mozRequestFullScreen();
			} else if (videoPlayer.webkitRequestFullscreen) {
				/* Chrome, Safari and Opera */
				videoPlayer.webkitRequestFullscreen();
			} else if (videoPlayer.msRequestFullscreen) {
				/* IE/Edge */
				videoPlayer.msRequestFullscreen();
			}
			$(this)
				.closest('.panel')
				.next('.display-box')
				.addClass('display-box--play');
		});

		$('.js-close-video-mobile').on('click', function() {
			var videoPlayer = document.getElementById(target);
			videoPlayer.pause();
			$('.display-box').removeClass('display-box--play');
			videoPlayer.webkitExitFullscreen();
		});

		function onVideoEnded(event) {
			var videoPlayer = document.getElementById(target);
			videoPlayer.pause();
			$('.display-box').removeClass('display-box--play');
			videoPlayer.webkitExitFullscreen();
		}

		this.slider = new Swiper('.swiper-container', {
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			on: {
				slideChange: function() {
					if(that.slider.activeIndex == 0){
						window.APP.headerManager.makeOpaque(false);
					}
					else{
						window.APP.headerManager.makeOpaque(true);
					}
					if (target) {
						var videoPlayer = document.getElementById(target);
						videoPlayer.pause();
					}
					$('.panel').removeClass('is-open');
				},
			},
		});
	}

	preload() {
		return new Promise((resolve, reject) => {
			this.preloadPromiseResolve = resolve;
			const tm = setTimeout(function() {
				$('.preloader__progress').css('opacity', 1);
			}, 10);
			this.timeouts.push(tm);
			this.bindEvents();
			this.loadScenes();
			this.loadMedia();
			// this.isLoaded = true;
		});
	}

	start() {
		var that = this;
		that.playAudios();
		that.canvas.animateLeaf();
		that.sceneEnter(0);
	}

	stop() {
		var that = this;
		this.intervals.forEach(function(interval) {
			clearInterval(interval);
		});
		this.timeouts.forEach(function(timeout) {
			clearTimeout(timeout);
		});
		this.preloader();
		this.unbindEvents();
		Howler.unload();
		window.APP.headerManager.canUpdateOnScroll = true;
	}

	loadScenes() {
		var that = this;
		$('.scene').each(function(index, element) {
			var initY = 0;
			var endY = 0;
			var mode = index <= 2 ? 'descent' : 'ascent';

			var scene = new Scene(element, mode, initY, endY);
			scene.setPreloadImages(that.config.preloadables[index], that.canvas);
			that.scenes.push(scene);
		});
	}

	unbindEvents() {
		var that = this;
		$(window).off('mousewheel DOMMouseScroll', that.events.scroll);
		$(window).off('resize', that.events.resize);
		$(document).off(
			'mozfullscreenchange webkitfullscreenchange fullscreenchange',
			that.events.detectFullscreen
		);
		$(document).off('click', '.btn-audio', that.events.btnAudio);
		$(document).off('click', '.js-hotspot', that.events.hotspot);
		$(document).off('click', '.js-content-video video', that.events.video);
		$(document).off('click', '.js-close-video', that.events.closeVideo);
		$(document).off('click', '.js-fullscreen-video', that.events.fullscreen);
	}

	bindEvents() {
		var that = this;
		$(window).on('mousewheel DOMMouseScroll', that.events.scroll);
		$(window).on('resize', that.events.resize);
		$(document).on(
			'mozfullscreenchange webkitfullscreenchange fullscreenchange',
			that.events.detectFullscreen
		);
		$(document).on('click', '.btn-audio', that.events.btnAudio);
		$(document).on('click', '.js-hotspot', that.events.hotspot);
		$(document).on('click', '.js-content-video video', that.events.video);
		$(document).on('click', '.js-close-video', that.events.closeVideo);
		$(document).on('click', '.js-fullscreen-video', that.events.fullscreen);
	}

	pauseContentVideos() {
		$('.js-content-video video').each(function(i, video) {
			video.pause();
		});
		$('.js-content-video').removeClass('is-visible');
	}

	transition(direction) {
		this.pauseContentVideos();
		var $video = $('.js-transition-video');
		var $videoOut =
			direction == 'down'
				? $('.js-scene[data-scene="2"] .js-scene-video:nth-child(2)')
				: $('.js-scene[data-scene="6"] .js-scene-video:nth-child(2)');
		var $videoIn =
			direction == 'down'
				? $('.js-scene[data-scene="6"] .js-scene-video:nth-child(2)')
				: $('.js-scene[data-scene="2"] .js-scene-video:nth-child(2)');
		var video = $video.get(0);
		video.play();
		$video.css({
			opacity: 1,
		});
		$videoOut.css({
			transition: '2000ms ease all',
			transform: 'scale(1.3)',
		});
		$('.scenes-ascent').show();

		const tm = setTimeout(function() {
			$video.css({
				opacity: 0,
			});
		}, 3500);
		this.timeouts.push(tm);

		const tm2 = setTimeout(function() {
			$videoIn.css({
				transition: '2000ms ease all',
				transform: 'scale(1.1)',
				'margin-top': '2.75vw',
			});
			$videoOut.css({
				transform: 'scale(1)',
			});
		}, 3000);
		this.timeouts.push(tm2);
	}

	upScenes_ascent() {
		var that = this;
		that.stateMoving();
		if (this.scenes[that.index - 1] && this.scenes[that.index - 1].isLoaded) {
			if (that.index - 1 > 3) {
				$('.hotspot').removeClass('is-visible');
				this.position = this.scenes[that.index - 1].endY - $(window).height();

				if (that.index - 1 == 5) {
					that.fadeAudios(2);
					that.fadeAudios(6);
					const tm = setTimeout(function() {
						$('.hotspot--mouvement').addClass('is-visible');
					}, that.timesAnimations.enterDelay[4]);
					that.timeouts.push(tm);
				} else {
					that.fadeAudios(that.index);
				}

				that.sceneLeave(that.index);
				that.index -= 1;
				that.playVideos(that.index);
				var duration = that.timesAnimations.duration[that.index];
				that.actionElements(
					that.index,
					that.index,
					that.timesAnimations.enterDelay[4],
					that.timesAnimations.exitDelay[that.index]
				);
				that.sceneEnter(duration);
				that.changeTrack(that.index);
				$('.js-transition').css('transition-duration', duration + 'ms');
				$('.js-transition').css('transform', `translate3d(0,${-that.position}px,0)`);
				if (that.index == 4) {
					that.setAnimationClock();
					that.setAnimationPinzas();
				}
			} else {
				if (that.index - 1 != 2) {
					that.position = 0;
					that.setAnimationExit();
					that.fadeOutTexts();
					that.actionElements(
						that.index - 1,
						that.index,
						that.timesAnimations.enterDelay[that.index - 1],
						that.timesAnimations.exitDelay[that.index]
					);
					const tm = setTimeout(function() {
						$('.js-design').addClass('is-visible');
					}, 750);
					that.timeouts.push(tm);
				} else {
					that.zoomEnd();
					$('.scene').removeClass('active');
				}
			}
		}
	}

	lastAscentTransition() {
		this.fadeAudios(this.index);
		this.index -= 1;
		var duration = this.timesAnimations.duration[this.index];
		this.sceneEnter(duration);
		this.playVideos(this.index);
		this.changeTrack(this.index);
		$('.js-transition').css('transition-duration', duration + 'ms');
		$('.js-transition').css('transform', `translate3d(0,${-this.position}px,0)`);
		const tm = setTimeout(function() {
			$('.element-04').addClass('active');
		}, duration - 1000);
		this.timeouts.push(tm);
	}

	setAnimationClock() {
		if (!this.animationClock) {
			var reloj = $('.canvas--clock');
			this.animationClock = new TimelineMax({ paused: true });
			var lastPosClock = this.scenes[5].height;

			this.animationClock
				.add(TweenLite.to(reloj, 4, { y: -lastPosClock, delay: 1.9 }))
				.add(TweenLite.to(reloj, 1.45, { x: '3%', rotation: '+=30' }), '-=4')
				.add(TweenLite.to(reloj, 2, { rotation: '-=45', x: 0 }), '-=1.75');
		}
		this.animationClock.play();
	}

	setAnimationPinzas() {
		if (!this.animationPinzaRight) {
			var pinzaRight = $('.pinza--right');
			var pinzaRightBack = $('.pinza--right-back');
			var pinzaLeft = $('.pinza--left');
			var pinzaRLeftOver = $('.pinza--left-over');
			var pinzaRLeftBack = $('.pinza--left-back');
			this.animationPinzaRight = new TimelineMax({ paused: true });

			this.animationPinzaRight
				.add(TweenLite.to(pinzaRight, 0.6, { x: '-68%', top: '8%', delay: 1.3 }))
				.add(
					TweenLite.to(pinzaRight, 1.45, {
						rotation: '+=60',
						x: '-60%',
						transformOrigin: '0% 100%',
					})
				)
				.add(
					TweenLite.to(pinzaLeft, 1, {
						left: '-18%',
						top: '-8%',
						transformOrigin: '100% 50%',
					}),
					'-=1'
				)
				.add(TweenLite.to(pinzaLeft, 1.2, { top: '-20%' }))
				.add(TweenLite.to(pinzaRight, 0.4, { top: '6%' }), '-=.6')
				.add(TweenLite.to(pinzaRight, 2, { top: '50%', x: '-10%' }))
				.add(
					TweenLite.to(pinzaLeft, 2, {
						rotation: '-=30',
						left: '-28%',
						transformOrigin: '100% 50%',
					}),
					'-=2.4'
				);
		}
		this.animationPinzaRight.play();
	}

	zoomEnd() {
		this.pauseContentVideos();
		this.setDots(7);
		$('.js-design').removeClass('is-visible');
		$('.js-presentation').addClass('is-visible');

		$('.js-scene[data-scene="3"] video').css({
			transition: '2000ms ease all',
			transform: 'scale(1.25)',
		});
	}

	setAnimationExit() {
		var that = this;

		if (!this.animationExit) {
			var pinzaLeft = $('.pinza--left');
			var reloj = $('.canvas--clock');
			this.animationExit = new TimelineMax({ paused: true });

			this.animationExit
				.add(
					TweenLite.to(pinzaLeft, 0.4, {
						rotation: '-=15',
						transformOrigin: '50% 50%',
					})
				)
				.add(TweenLite.to(reloj, 0.5, { rotation: '-=180' }), '-=.4')
				.add(
					TweenLite.to(reloj, 1, {
						y: '-300%',
						onComplete: function() {
							reloj.css('transition-delay', '0s');
							that.lastAscentTransition();
						},
					}),
					'-=.5'
				)
				.add(TweenLite.to(reloj, 0.4, { opacity: 0 }))
				.add(TweenLite.to(pinzaLeft, 1, { left: '-100%' }), '-=.8');
		}
		this.animationExit.play();
	}

	downScenes() {
		var scenes = this.scenes;
		var index = this.index;

		this.stateMoving();
		if (scenes[index + 1] && scenes[index + 1].isLoaded) {
			if (index != 1) {
				this.position = scenes[index + 1].endY - $(window).height();
				$('.parallax').addClass('move');
			} else {
				this.position = this.totalHeightDescent - $(window).height();
				$('.scenes-descent').css(
					'transition-timing-function',
					'cubic-bezier(0.785, 0.135, 0.615, 0.988)'
				);
				$('.element-03').css('top', this.position);
			}

			var duration = this.timesAnimations.duration[index];

			this.sceneLeave(index);
			this.index += 1;
			this.playVideos(this.index);
			this.changeTrack(this.index);

			$('.js-transition').css('transition-duration', duration + 'ms');
			$('.js-transition')
				.css('transform', `translate3d(0,${-this.position}px,0)`)
				.addClass('edgeBackground');

			this.sceneEnter(duration);

			const tm = setTimeout(function() {
				$('.js-transition').removeClass('edgeBackground');
			}, duration);
			this.timeouts.push(tm);

			this.actionElements(
				this.index,
				this.index - 1,
				this.timesAnimations.enterDelay[this.index - 1],
				this.timesAnimations.exitDelay[this.index - 1]
			);
		}
	}

	downScenes_ascent() {
		var index = this.index;
		this.stateMoving();
		if (index + 1 <= 6) {
			if (index + 1 != 6) {
				this.position = scenes[index + 1].endY - $(window).height();
			} else {
				this.position = this.totalHeightAscent - $(window).height();
			}
			this.fadeAudios(index);
			this.sceneLeave(index);
			this.index += 1;
			this.playVideos(this.index);
			this.changeTrack(this.index);
			$('.js-transition').css('transition-duration', '3000ms');
			$('.js-transition').css('transform', `translate3d(0,${-this.position}px,0)`);
			if (this.index == 5) {
				this.animationClock.reverse();
				this.animationPinzaRight.reverse();
			}
			if (this.index == 4) {
				this.animationExit.reverse();
			}
		} else {
			this.transition('back');
			this.position = this.totalHeightDescent - $(window).height();

			const tm = setTimeout(function() {
				$('.js-transition').css('transform', `translate3d(0,${-this.position}px,0)`);
			}, 500);
			this.timeouts.push(tm);
			$('.scenes-descent').fadeIn(3000);
			this.index = 2;
			this.stateMoving();
			this.playVideos(index);
		}
	}

	fadeOutTexts() {
		const tm = setTimeout(function() {
			$('.js-scene .scene__content').removeClass('is-visible');
		}, 500);
		this.timeouts.push(tm);
	}

	fadeInTexts(index, duration) {
		duration = duration || 1500;
		const tm = setTimeout(function() {
			$('.js-scene[data-scene="' + index + '"] .scene__content').addClass('is-visible');
		}, duration);
		this.timeouts.push(tm);
	}

	setDots(dot) {
		dot = dot || this.index;
		$('.dot').removeClass('is-active');
		$('.dot')
			.eq(dot)
			.addClass('is-active');
	}

	showScrollButton() {
		$('.btn-scroll').addClass('is-visible');
	}

	hideScrollButton() {
		$('.btn-scroll').removeClass('is-visible');
	}

	sceneLeave(index) {
		var that = this;
		if (index === 0) {
			this.hideScrollButton();
		}
		this.fadeAudios(index);
		this.fadeOutTexts();
		const tm = setTimeout(function() {
			that.pauseContentVideos();
		}, 2000);
		this.timeouts.push(tm);
	}

	enableUI(duration) {
		var that = this;
		$('.js-chrome-audio').removeClass('is-visible');
		const tm = setTimeout(function() {
			$('header > div').addClass('opaque');
			$('.dots, .js-button-collections').addClass('is-visible');
		}, that.timesAnimations.enterDelay[1]);
		this.timeouts.push(tm);
	}

	sceneEnter(duration) {
		var that = this;
		if (this.index === 1) {
			that.enableUI();
		}

		this.setDots(this.dotOrder[this.index]);
		this.fadeInTexts(this.index, duration);
		var indexSceneEnter =
			this.scenes[this.index].mode === 'descent' ? this.index + 1 : this.index - 1;

		// const newScene = this.scenes[indexSceneEnter];
		// this.loadHotspotVideo(newScene);

		var nextScene = this.scenes[indexSceneEnter];
		if (nextScene) {
			if (!nextScene.isLoaded) {
				$('.js-loader').show();
			}
			this.sceneLoader = setInterval(function() {
				that.updateSceneProgress(nextScene, that);
			}, 1000);

			this.intervals.push(this.sceneLoader);
		}
		if (indexSceneEnter === 4) {
			this.canvas.animateClock();
		}
	}

	loadSources(media) {
		$.each($(media).find('source'), function(i, source) {
			var $source = $(source);
			var $sourceData = mediaQuery('retina') ? $source.data('src') : $source.data('srcnr');
			$source.attr('src', $sourceData);
		});
	}

	loadNextVideo(next) {
		var that = this;
		var videos = this.videos;
		if (videos[next]) {
			var video = videos[next];
			this.loadVideo(video, function() {
				var $scene = $(video)
					.parents('.js-scene')
					.eq(0);
				var dataScene = $scene.attr('data-scene');
				var scene = that.scenes[dataScene];
				video.dataset.percent = 100;
				that.setSceneHeight(video);
				next++;

				if (scene) {
					scene.loadedVideos++;
					if (scene.loadedVideos < scene.videos.length) {
						that.loadNextVideo(next);
					} else {
						scene.loadImages(function() {
							that.loadHotspotVideo(scene);
							that.loadNextVideo(next);
						});
					}
				} else {
					that.loadNextVideo(next);
				}
			});
		}
	}

	loadVideo(video, callback) {
		this.loadSources(video);
		if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
			video.autoplay = true;
		}
		video.addEventListener('progress', this.loadProgress, false);
		video.addEventListener('loadeddata', callback, false);
		video.load();
	}

	loadMedia() {
		this.loadAudios();
		this.loadVideos();
	}

	playError() {
		this.soundEnabled = false;
		$('.btn-audio').removeClass('enable');
	}

	autoPlay() {
		if (this.soundEnabled) {
			$('.btn-audio').addClass('enable');
			if (this.isChrome) {
				$('.js-chrome-audio').removeClass('is-visible');
			}
		}
	}

	playAudios() {
		var that = this;
		//Promise audio rejection browser(Chrome)
		var firstAudio = this.sceneAudios[0].enter[0].file;
		firstAudio.once('play', function() {
			that.soundEnabled = true;
			that.autoPlay();
		});
		firstAudio.on('playerror', function() {
			that.playError();
		});
		firstAudio.play();

		if (!Howler.ctx || Howler.ctx.state === 'suspended') {
			that.playError();
		}
	}

	loadAudios() {
		var that = this;
		var setAudio = function(audio) {
			var sound = new Howl({
				src: [audio.src, audio.aac],
				loop: audio.loop,
				volume: 1,
			});
			sound.on('unlock', function() {
				that.autoPlay();
			});
			audio.file = sound;
			return sound;
		};

		this.sceneAudios.forEach(function(scene, sceneIndex) {
			scene.enter.forEach(function(audio) {
				var sound = setAudio(audio);
				that.setSceneMedia(sound, sceneIndex);
			});
			scene.leave.forEach(function(audio) {
				setAudio(audio);
			});
		});
	}

	loadVideos() {
		var that = this;
		var first = 2;
		var count = 0;

		const sortedScenes = this.scenes.slice(0).sort(function(a, b) {
			const aIndex = a.element.getAttribute('data-preloadable');
			const bIndex = b.element.getAttribute('data-preloadable');
			if (aIndex < bIndex) {
				return -1;
			}
			if (aIndex > bIndex) {
				return 1;
			}
			return 0;
		});

		const videos = sortedScenes.reduce(function(acc, current) {
			return acc.concat([...current.element.querySelectorAll('.js-scene-video')]);
		}, []);

		// $.each($(".js-scene-video"), function(i, video) {
		videos.forEach(function(video, i) {
			video.dataset.percent = 0;
			that.videos.push(video);

			var $scene = $(video)
				.parents('.js-scene')
				.eq(0);
			var dataScene = $scene.attr('data-scene');
			var scene = $scene.index();
			that.setSceneMedia(video, dataScene);

			if (i + 1 <= first) {
				that.loadVideo(video, function() {
					that.setSceneHeight(video);
					video.dataset.percent = 100;
					count++;
					if (count === first) {
						// that.loadNextVideo(count);
						that.scenes[0].loadImages(function() {
							that.loaded(count);
						});
					}
				});
			}
		});
	}

	setSceneMedia(media, scene) {
		var that = this;
		if (that.scenes[scene]) {
			if (media instanceof HTMLVideoElement) {
				that.scenes[scene].videos.push(media);
			} else {
				that.scenes[scene].audios.push(media);
			}
		}
	}

	setSceneHeight(video) {
		var $scene = $(video)
			.parents('.js-scene')
			.eq(0);
		var index = $scene.attr('data-scene');
		if (parseInt(index) !== -1) {
			$(video).height($(video).height()); // Firefox fix
			this.scenes[index].height = this.scenes[index].height + Math.floor($(video).height());
			$scene.height(this.scenes[index].height);

			// this.setScenePos(index, this.scenes[index].mode, this.scenes[index].height);
			var that = this;
			this.scenes.forEach(function(scene) {
				const sceneIndex = parseInt(scene.element.getAttribute('data-scene'));
				if (sceneIndex != -1) {
					that.setScenePos(
						sceneIndex,
						that.scenes[sceneIndex].mode,
						that.scenes[sceneIndex].height
					);
				}
			});
		}
	}

	centerContent() {
		var contentTextPosition = $(window).height() - $('.scene--2').height();
		$('.scene__content--center-text').css('bottom', contentTextPosition + 'px');
	}

	setScenePos(sceneIndex, mode, height) {
		var lastScene = 6;
		if (mode == 'descent') {
			var posTop = this.scenes[sceneIndex - 1] ? this.scenes[sceneIndex - 1].endY : 0;
			var posBottom = posTop + height;
			this.totalHeightDescent = posBottom;
			this.centerContent();
		} else {
			var posTop = sceneIndex > 3 ? this.scenes[sceneIndex - 1].endY : 0;
			var posBottom = posTop + height;
			this.totalHeightAscent = posBottom;
		}

		this.scenes[sceneIndex].initY = posTop;
		this.scenes[sceneIndex].endY = posBottom;

		if (sceneIndex == lastScene) {
			this.setCanvasClock();
		}
	}

	setCanvasClock() {
		var scenes = this.scenes;
		var that = this;

		$('.container-clock').height(this.totalHeightAscent);
		var marginTopClock = ($(window).height() * 4.5) / 100;
		var initPositionClock = scenes[6].height + marginTopClock;
		if (this.canvas_isSeted) {
			$('.canvas--clock').css('bottom', initPositionClock);
		}
		this.canvas_isSeted = true;
	}

	playVideo(video) {
		video.play();
	}

	pauseVideo(video) {
		if (!video.paused) {
			video.pause();
		}
	}

	playVideos(index) {
		var that = this;
		var scenes = this.scenes;
		var prev = scenes[index].mode === 'descent' ? index - 1 : index + 1;
		var next = scenes[index].mode === 'descent' ? index + 1 : index - 1;
		scenes.forEach(function(scene, sindex) {
			if (sindex === index || sindex === prev || sindex === next) {
				scene.videos.forEach(that.playVideo);
			} else {
				// scene.videos.forEach(that.pauseVideo);
			}
		});
	}

	fadeAudios(index) {
		var that = this;

		var delay = that.sceneAudios[index][that.direction].fadeOut;

		const tm = setTimeout(function() {
			that.sceneAudios[index].enter.forEach(function(audio) {
				that.fadeOut(audio.file, 300);
			});
			that.sceneAudios[index].leave.forEach(function(audio) {
				that.fadeOut(audio.file, 300);
			});
		}, delay);
		this.timeouts.push(tm);
	}

	changeTrack(index) {
		var that = this;
		var delay = that.sceneAudios[index][that.direction].fadeIn;

		const tm = setTimeout(function() {
			that.sceneAudios[index].enter.forEach(function(audio) {
				if (!(audio.avoid && that.direction === 'up' && index === 5)) {
					//Submerge.ogg al bajar en la escena 5
					that.fadeIn(audio.file, 200);
				}
			});
		}, delay);

		this.timeouts.push(tm);
	}

	fadeOut(audio, time) {
		audio.fade(1.0, 0.0, time);
		const tm = setTimeout(function() {
			audio.pause();
		}, time);
		this.timeouts.push(tm);
	}

	fadeIn(audio, time) {
		audio.play();
		audio.fade(0.0, 1.0, time);
	}

	loadProgress() {
		if (this.buffered.length > 0) {
			var range = 0;
			var bf = this.buffered;
			var time = this.currentTime;

			while (!(bf.start(range) <= time && time <= bf.end(range))) {
				range += 1;
			}
			var loadStartPercentage = bf.start(range) / this.duration;
			var loadEndPercentage = bf.end(range) / this.duration;
			var loadPercentage = loadEndPercentage - loadStartPercentage;
			if (this.dataset.percent !== '100') {
				this.dataset.percent = loadPercentage * 100;
			}
		}
	}

	updateSceneProgress(scene, that) {
		if (!scene || !scene.videos.length) {
			return;
		}
		//var sum = 0;
		if (that.index == 0) {
			var sum = 40;
		} else {
			var sum = 0;
		}

		scene.videos.forEach(function(video) {
			sum = sum + parseFloat(video.dataset.percent);
		});
		sum = sum / scene.videos.length;
		const sceneImages = scene.images.length * 5;

		const notLoadedSceneImages = scene.images.reduce(function(acc, current) {
			if (!current.loaded) {
				acc += 5;
			}
			return acc;
		}, 0);

		var angle = (sum * (360 - notLoadedSceneImages)) / 100;

		var circleLoader = $('#stroke-circle');
		circleLoader.attr('stroke-dasharray', angle + ', 20000');

		if (sum >= 100) {
			circleLoader.attr('stroke-dasharray', 360 + ', 20000');
			scene.isLoaded = true;
			clearInterval(that.sceneLoader);
			const tm = setTimeout(function() {
				$('.js-loader').fadeOut('fast');
				circleLoader.attr('stroke-dasharray', 0 + ', 20000');
				sum = 0;
				if (that.index === 0) {
					that.showScrollButton();
					that.moving = false;
				}
			}, 1300);
			that.timeouts.push(tm);
		}
	}

	toggleSound() {
		Howler.mute(this.soundEnabled);
		if (this.soundEnabled === true) {
			this.soundEnabled = false;
			$('.btn-audio').removeClass('enable');
		} else {
			Howler.mute(false);
			this.soundEnabled = true;
			$('.btn-audio').addClass('enable');
		}
	}

	actionElements(sceneIndex, transitionIndex, delayEnter, delayExit) {
		var activeScene = $('.scene[data-scene="' + sceneIndex + '"]');
		const tm = setTimeout(function() {
			$('.scene').removeClass('active');
		}, delayExit);
		this.timeouts.push(tm);

		const tm2 = setTimeout(function() {
			activeScene.addClass('active');
		}, delayEnter);
		this.timeouts.push(tm2);
	}

	stateMoving() {
		var that = this;
		this.moving = true;
		const tm = setTimeout(function() {
			that.moving = false;
		}, 5000);
		this.timeouts.push(tm);
	}

	loadHotspotVideo(scene) {
		const videos = [...scene.element.querySelectorAll('.scene__video')];
		if (videos.length > 0) {
			videos.forEach(function(video){
				video.setAttribute('src', video.getAttribute('data-src'));
			})

		}
	}
}
