export function mediaQuery(query) {
	var breakpoints = {
		mobile: '(min-width: 320px)',
		desktop: '(min-width: 768px)',
		retina:
			'only screen and (-webkit-min-device-pixel-ratio:2), only screen and (min--moz-device-pixel-ratio:2), only screen and (min-device-pixel-ratio:2), only screen and (min-resolution:2dppx), only screen and (min-resolution:192dpi)',
		mobileLandscape:
			'only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)',
	};
	switch (query) {
		case 'mobile':
			return (
				(window.matchMedia(breakpoints.mobile).matches &&
					!window.matchMedia(breakpoints.tablet).matches) ||
				navigator.userAgent.match(/iPad/i) != null
			);
		case 'tablet':
			return (
				window.matchMedia(breakpoints.tablet).matches &&
				!window.matchMedia(breakpoints.desktop).matches
			);
		case 'desktop':
			return (
				window.matchMedia(breakpoints.desktop).matches &&
				navigator.userAgent.match(/iPad/i) == null
			);
		case 'retina':
			return window.matchMedia(breakpoints.retina).matches;
		case 'mobileLandscape':
			return window.matchMedia(breakpoints.mobileLandscape).matches;
		default:
			return false;
	}
}

export function getCurrentBreakpoint() {
	var current = mediaQuery('mobile') ? 'mobile' : null;
	current = mediaQuery('tablet') ? 'tablet' : current;
	current = mediaQuery('desktop') ? 'desktop' : current;
	return current;
}
