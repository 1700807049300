import Page from '../../page';
import Experience from '../../experience/experience';
import getBodySize from '../../utils/get-body-size';
import getIsTouchDevice from '../../utils/get-is-touch-device';
import { mediaQuery } from '../../experience/responsive';

const mobileBgs = [
	{
		selector: 'slide--decouvrez',
		url: '/images/mobile/bg_mobile_1.jpg',
		urlRetina: '/images/mobile/bg_mobile_1@2x.jpg',
	},
	{
		selector: 'slide--history',
		url: '/images/mobile/bg_mobile_2.jpg',
		urlRetina: '/images/mobile/bg_mobile_2@2x.jpg',
	},
	{
		selector: 'slide--composants',
		url: '/images/mobile/bg_mobile_3.jpg',
		urlRetina: '/images/mobile/bg_mobile_3@2x.jpg',
	},
	{
		selector: 'slide--ornements',
		url: '/images/mobile/bg_mobile_3a.jpg',
		urlRetina: '/images/mobile/bg_mobile_3a@2x.jpg',
	},
	{
		selector: 'slide--mouvement',
		url: '/images/mobile/bg_mobile_3b.jpg',
		urlRetina: '/images/mobile/bg_mobile_3b@2x.jpg',
	},
	{
		selector: 'slide--certification',
		url: '/images/mobile/bg_mobile_4a.jpg',
		urlRetina: '/images/mobile/bg_mobile_4a@2x.jpg',
	},
	{
		selector: 'slide--watches',
		url: '/images/mobile/bg_mobile_5.jpg',
		urlRetina: '/images/mobile/bg_mobile_5@2x.jpg',
	},
	{
		selector: 'slide--vaulion',
		url: '/images/mobile/bg_mobile_6.jpg',
		urlRetina: '/images/mobile/bg_mobile_6@2x.jpg',
	},
	{
		selector: 'slide--chatel',
		url: '/images/mobile/bg_mobile_7.jpg',
		urlRetina: '/images/mobile/bg_mobile_7@2x.jpg',
	},
	{
		selector: 'slide--chasseron',
		url: '/images/mobile/bg_mobile_8.jpg',
		urlRetina: '/images/mobile/bg_mobile_8@2x.jpg',
	},
	{
		selector: 'slide--conception',
		url: '/images/mobile/bg_mobile_4b.jpg',
		urlRetina: '/images/mobile/bg_mobile_4b@2x.jpg',
	},
];

export default class Home extends Page {
	constructor() {
		super('Home');
	}

	_setup(pageConfig, resolve) {
		window.APP.languageSelector.setAjaxNavigation(false);

		if(!getIsTouchDevice()){
			pageConfig.preloadables = this.getPreloadItems('desktop');
			this.experience = new Experience(pageConfig);
			this.experience.preload().then(function() {
				resolve(this);
			});
		}
		else {
			pageConfig.preloadables = this.getPreloadItems('mobile');
			this.experience = new Experience(pageConfig);
			this.addMobileCss(pageConfig.assetsRootPath);
			this.experience.preloadMobile().then(function() {
				resolve(this);
			});
		}
	}

	getPreloadItems(experienceType) {
		let preloadItems;
		if (experienceType == 'desktop') {
			return [...this.rootNode.querySelectorAll('[data-preloadable]')].reduce(
				(acc, current) => {
					const preloadIndex = parseInt(current.getAttribute('data-preloadable'));

					if (!acc[preloadIndex]) {
						acc[preloadIndex] = {
							preloadIndex,
							items: [],
						};
					}

					if (current.hasAttribute('data-scene')) {
						acc[preloadIndex].sceneIndex = parseInt(current.getAttribute('data-scene'));
					}

					const preloadables = [
						...current.querySelectorAll('img[data-src], [data-poster]'),
					];

					acc[preloadIndex].items = acc[preloadIndex].items.concat(preloadables);

					if (current.hasAttribute('data-has-sprite')) {
						acc[preloadIndex].sprite = current.getAttribute('data-has-sprite');
					}

					return acc;
				},
				[]
			);
		} else {
			return [...this.rootNode.querySelectorAll('.experience-mobile [data-src]')];
		}
	}

	addMobileCss(mediaRoot) {
		const defaultStyle = document.createElement('style');
		defaultStyle.setAttribute('media', 'screen');

		const retinaStyle = document.createElement('style');
		retinaStyle.setAttribute(
			'media',
			'only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)'
		);

		// WebKit hack :(
		defaultStyle.appendChild(document.createTextNode(''));
		retinaStyle.appendChild(document.createTextNode(''));

		document.head.appendChild(defaultStyle);
		document.head.appendChild(retinaStyle);

		mobileBgs.forEach(function(rule) {
			defaultStyle.sheet.insertRule(
				`.${rule.selector} {background-image: url(${mediaRoot}${rule.url})}`
			);
			defaultStyle.sheet.insertRule(
				`.${rule.selector} {background-image: url(${mediaRoot}${rule.urlRetina})}`
			);
		});
	}

	_enter(resolve) {
		if(!getIsTouchDevice()){
			this.experience.start();
		} else {
			this.experience.mobile();
		}
		resolve(this);
	}

	_exit(resolve) {
		window.APP.languageSelector.setAjaxNavigation(true);
		this.experience.stop();
		resolve(this);
	}
}
